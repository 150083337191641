import React, { useEffect, useState, useContext } from "react";
import documentsServices from "../services/documentsServices";
import { OverlayContext } from "../contexts/OverlayContext";
import Button from "./bootstrap/Button";
import Card, { CardHeader, CardBody } from "./bootstrap/Card";
import useEventListener from "../hooks/useEventListener";
import { useTranslation } from 'react-i18next';
const Details = ({ hlevel, type, tag, description }) => {

    const {
        currentNodeSelected,
        setCurrentNodeSelected,
        detailsIsOpen, setDetailsIsOpen,
        offCanvasOpen
    } = useContext(OverlayContext);
    const [details, setDetails] = useState({
        hlevel, type, tag, description
    })
    const [previousID, setPreviousID] = useState("")
    const [loaded, setLoaded] = useState(false)

    const { t } = useTranslation('menu');

    const closeDetails = (event) => {

        const idEvent = event.target.id;
        if (idEvent === "details-close") {
            setDetailsIsOpen(false)
        }
    };

    useEventListener('mousedown', closeDetails);

    const handleCustomEvent = (event) => {
        console.log({ eventDetail: event })
        if (event.detail !== undefined) {
            setCurrentNodeSelected(event.detail)
        }

        if (event.detail.detailsClicked === true) {
            setDetailsIsOpen(true)
        }
        if (event.detail !== undefined) {
            if (event.detail._id !== previousID) {
                documentsServices.getAllPropierties(event.detail._id)
                    .then(res => {
                        if (res.ok) {
                            setDetails(res.documents)
                            setPreviousID(event.detail._id)
                        }
                    })
                    .catch(err => console.error({ err }))
            }


        }

    };

    useEffect(() => {
        if (offCanvasOpen === false) {
            setDetailsIsOpen(false)
        }
    }, [offCanvasOpen]);

    useEffect(() => {
        if (!loaded) {
            window.addEventListener("detailsClickEvent", handleCustomEvent);
            setLoaded(true)
        }
    }, []);

    return (
        <Card
            id="nodeDetails"
            className={detailsIsOpen ? "" : "hidden"}

        >
            <CardHeader
                className="row"
            >
                <span className="d-flex align-items-end h-100 col-9">
                    <h5>
                        {t("Details")}
                    </h5>
                </span>
                <Button
                    id="details-close"
                    icon="close"
                    className="col-3"
                ></Button>
            </CardHeader>

            <CardBody>


                <table id="details-element" className="">
                    <tbody>
                        <tr>
                            <th >{t("Name")}</th>
                            <td >{currentNodeSelected?.name}</td>

                        </tr>
                        {(details !== undefined && Array.isArray(details)) ? details.map((elemento, index) => (
                            <tr>
                                <th key={index + elemento.property + "th"}>{elemento.property}</th>
                                {elemento.value !== null ?
                                    <td key={index + elemento.value}
                                    >{elemento?.value.match(/^https?:\/\/[^\s]+/i) ?
                                        <a
                                            className="link ellipsis-text"
                                            target="_blank"
                                            href={elemento.value}>Link</a> : elemento.value
                                        }</td>
                                    : <></>}

                            </tr>
                        )) : <></>}

                    </tbody>
                </table>
            </CardBody>
        </Card>
    );
};



export default Details;