import React, { useEffect } from 'react';
import Button, { ButtonGroup } from './bootstrap/Button';
import { useContext } from 'react';
import { OverlayContext } from '../contexts/OverlayContext';

const CurrentNodeActions = () => {
    const {
        currentNodeSelected
    } = useContext(OverlayContext);
    function dispatchCustomEvent(eventName, data) {
        const event = new CustomEvent(eventName, { detail: data });
        console.log("EVENTO")
        window.dispatchEvent(event);

        if (eventName === "positionEvent") {
            setTimeout(function () {
                console.log('Han pasado 1 segundo');
                // clickIcon = false;
            }, 1000);
        }//css-kdwx3d
    }
    useEffect(() => {
        console.log({ currentNodeSelected })
    }, [currentNodeSelected])
    return (
        <ButtonGroup>
            <Button
                disabled={currentNodeSelected.sweepCameraPosition && currentNodeSelected.sweepCameraPosition.sweepointUUID === null ? true : false}
                color={currentNodeSelected.sweepCameraPosition && currentNodeSelected.sweepCameraPosition.sweepointUUID === null ? 'dark' : 'primary'}
                icon="Place"
                onClick={() => {
                    dispatchCustomEvent("positionEvent", currentNodeSelected);
                }}
            >
            </Button>
            <Button
                color="primary"
                icon="construction"
                onClick={() => {
                    dispatchCustomEvent("nodeOptionEvent", currentNodeSelected);
                    dispatchCustomEvent("detailsClickEvent", { ...currentNodeSelected, detailsClicked: true });
                }}
            >
            </Button>
            <Button
                color="primary"
                icon="Info"
                onClick={() => {
                    dispatchCustomEvent("detailsClickEvent", { ...currentNodeSelected, detailsClicked: true });
                    console.log("ID");
                }}
            ></Button>

        </ButtonGroup>
    );
}

export default CurrentNodeActions;
