
let THREE;
let group;
let units = "m";
import { lastLineID } from "./matterportHelper";
import matterportHelper from "./matterportHelper";
function Line() {
    let labelRenderer;
    let renderer;
    this.inputs = {
        points: [],
        scene: undefined,
        camera: undefined,
        index: "",
        units: "",
    };
    const clearLines = () => {
        console.log("Destroy");
        group.clear();
        const parentContainer = document.getElementById("matteport-container");
        for (let i = parentContainer.children.length - 1; i >= 0; i--) {
            if (parentContainer.children[i].className === "label-line-matterport iframe") {
                parentContainer.children[i].remove()
            }
        }
        group.removeFromParent();
    }
    const redrawLines = (event) => {
        console.log({ index: this.inputs.index, lastLineID })


        clearLines();
        const points = event.detail;
        console.log("REDRAWE", { points });
        document.removeEventListener('redrawLines', redrawLines);



    };
    const updateTagUnits = (event) => {
        // this.inputs = "Test";
        console.log("UPADETE LINE UNIT");
        console.log({ event })
        units = ""
        const elements = document.querySelectorAll('.label-matterport-measurements');
        console.log({ elements })
        // Cambiar el contenido de texto de cada elemento a "pizza"
        elements.forEach(element => {
            const numeroDecimal = parseFloat(element.textContent.match(/\d+(\.\d+)?/)[0]);
            let newString = numeroDecimal;

            let unit = ""; // Variable para almacenar la unidad (inicialmente sin valor)
            if (event.detail.newValue === "metric") {
                localStorage.setItem('unitType', 'metric'); // Puedes cambiar 'metric' por el valor que desees
                console.log({ text: element.textContent });
                newString = element.realValue;// matterportHelper.metricToImperial(element.realValue, false)
                unit = "m"
            } else if (event.detail.newValue === "imperial") {
                unit = "";
                const imperialValues = matterportHelper.metricToImperial(element.realValue, true);
                newString = `${imperialValues.wholeFeet}'${imperialValues.wholeInches}''`;
                localStorage.setItem('unitType', 'imperial'); // Puedes cambiar 'metric' por el valor que desees

            } else {
                // Manejo de otro caso (opcional)
                console.log("Evento no reconocido");
            }
            element.textContent = newString + unit;
        });
    }
    document.addEventListener('destroyLines', clearLines);
    document.addEventListener('redrawLines', redrawLines);
    document.addEventListener('tagUnits', updateTagUnits);
    this.onInit = function () {


        const width = window.innerWidth;
        const height = window.innerHeight;
        const Scene = this.context.scene;
        const camera = this.context.camera;
        renderer = this.context.renderer;

        this.inputs.scene = this.context.scene;
        this.inputs.camera = this.context.camera;
        console.log({ inputs: this.inputs })
        const iframe = document.getElementById('showcase');
        THREE = this.context.three;
        //this.materialBlue = new THREE.LineMaterial({ color: 0x0000ff, linewidth: 3.5, linecap: "square", linejoin: "round" });
        const geometryMaterial = {

            /* linewidth: 1, // in world units with size attenuation, pixels otherwise
             vertexColors: true,
 
             dashed: false,
             alphaToCoverage: true,
             */
            dashed: true,
            alphaToCoverage: true,
            vertexColors: true,
            linewidth: 15,
            resolution: new THREE.Vector2(width, height)
        }

        const z = 3;
        const colors = [1, 1, 1, 1, 1, 1];
        const pointstest = [0, 0, 0, 0, 0, z];

        const geometry = new THREE.LineGeometry();

        //geometry.setPositions(pointstest);
        // geometry.setColors(colors);

        const initialPoints = [];
        const points = [];

        this.inputs.points.forEach((point) => {
            initialPoints.push(new THREE.Vector3(point.x, point.y, point.z));
        })

        const z0 = new THREE.Vector3(initialPoints[0].x, initialPoints[0].y, initialPoints[0].z);
        const z1 = new THREE.Vector3(initialPoints[0].x, initialPoints[0].y, initialPoints[1].z);
        const z2 = new THREE.Vector3(initialPoints[0].x, initialPoints[1].y, initialPoints[1].z);
        const z3 = new THREE.Vector3(initialPoints[0].x, initialPoints[1].y, initialPoints[1].z);
        const z4 = new THREE.Vector3(initialPoints[1].x, initialPoints[1].y, initialPoints[1].z);
        const z5 = new THREE.Vector3(initialPoints[0].x, initialPoints[0].y, initialPoints[0].z);
        const z6 = new THREE.Vector3(initialPoints[1].x, initialPoints[1].y, initialPoints[1].z);


        points.push(z0);
        points.push(z1);
        points.push(z2);
        points.push(z3);
        points.push(z4);
        points.push(z5);
        points.push(z6);

        // const geometry1 = new THREE.BufferGeometry().setFromPoints([z0, z1]);
        // const geometry = new THREE.LineGeometry();
        const geometry2 = new THREE.LineGeometry();
        //new THREE.BufferGeometry().setFromPoints([z1, z2]);
        const geometry3 = new THREE.LineGeometry();
        //new THREE.BufferGeometry().setFromPoints([z2, z3]);
        const geometry4 = new THREE.LineGeometry();
        //new THREE.BufferGeometry().setFromPoints([z3, z4]);
        const geometry5 = new THREE.LineGeometry();
        //new THREE.BufferGeometry().setFromPoints([z4, z5]);
        //  this.line1 = new THREE.Line(geometry1, this.materialBlue);

        geometry.setColors(colors);
        geometry2.setColors(colors);
        geometry3.setColors(colors);
        geometry4.setColors(colors);
        geometry5.setColors(colors);

        geometry.setPositions([initialPoints[0].x, initialPoints[0].y, initialPoints[0].z, initialPoints[0].x, initialPoints[0].y, initialPoints[1].z]);
        geometry2.setPositions([initialPoints[0].x, initialPoints[0].y, initialPoints[1].z, initialPoints[0].x, initialPoints[1].y, initialPoints[1].z]);
        geometry3.setPositions([initialPoints[0].x, initialPoints[1].y, initialPoints[1].z, initialPoints[0].x, initialPoints[1].y, initialPoints[1].z]);
        geometry4.setPositions([initialPoints[0].x, initialPoints[1].y, initialPoints[1].z, initialPoints[1].x, initialPoints[1].y, initialPoints[1].z]);
        geometry5.setPositions([initialPoints[1].x, initialPoints[1].y, initialPoints[1].z, initialPoints[0].x, initialPoints[0].y, initialPoints[0].z]);

        this.line2 = new THREE.Line2(geometry2, new THREE.LineMaterial({ ...geometryMaterial, color: 0x0000ff }));
        this.line3 = new THREE.Line2(geometry3, new THREE.LineMaterial({ ...geometryMaterial, color: 0xff0000 }));
        this.line4 = new THREE.Line2(geometry4, new THREE.LineMaterial({ ...geometryMaterial, color: 0x00ff00 }));
        this.line5 = new THREE.Line2(geometry5, new THREE.LineMaterial({ ...geometryMaterial, color: 0xf6512e }));
        this.line6 = new THREE.Line2(geometry, new THREE.LineMaterial({ ...geometryMaterial, color: 0xff0000 }));

        group = new THREE.Group();
        //group.add(this.line1);
        group.add(this.line2);
        //group.add(this.line3);
        group.add(this.line4);
        group.add(this.line5);
        group.add(this.line6);

        console.log({ lines: this.line4 })
        labelRenderer = new THREE.CSS2DRenderer();
        labelRenderer.setSize(iframe.offsetWidth, window.innerHeight);
        labelRenderer.domElement.style.position = "absolute";
        labelRenderer.domElement.style.top = "0px";
        labelRenderer.domElement.style.display = "block";


        labelRenderer.domElement.className = "label-line-matterport iframe"
        labelRenderer.domElement.id = "label-render-matterport"

        document.getElementById("matteport-container").appendChild(labelRenderer.domElement);
        group.add(createMeasurementsLabel("z", [z1, z2], this.inputs.units));
        group.add(createMeasurementsLabel("y", [z0, z1], this.inputs.units));
        group.add(createMeasurementsLabel("x", [z3, z4], this.inputs.units));
        Scene.add(group);


        // animate();
        renderer.render(Scene, camera);
        labelRenderer.render(Scene, camera);
    };


    this.onTick = function (tickDelta) {


        labelRenderer.render(this.inputs.scene, this.inputs.camera);


    };

    this.onDestroy = function () {
        clearLines();
        if (this.inputs.index !== lastLineID) {
            clearLines();
        }
    };
};

function createMeasurementsLabel(text, initialPoints, text2) {
    console.log({ text2 })
    let unitType = localStorage.getItem("user_preference/unit_type");
    const dx = Math.pow(initialPoints[1].x - initialPoints[0].x, 2);
    const dy = Math.pow(initialPoints[1].y - initialPoints[0].y, 2);
    const dz = Math.pow(initialPoints[1].z - initialPoints[0].z, 2);
    const distance = Math.abs(Math.sqrt(dx + dy + dz)).toFixed(3)
    if (unitType === undefined) {
        unitType = "no"
    }
    let textContent = text + " " + ` ${distance}` + units;
    if (unitType === "imperial") {

        const imperialValues = matterportHelper.metricToImperial(distance, true);
        textContent = `${imperialValues.wholeFeet}'${imperialValues.wholeInches}''`;
    } else {

        if (!textContent.endsWith('m')) { textContent += 'm'; }
    }
    const labelDiv = document.createElement("div");
    labelDiv.className = "label-matterport-measurements";
    labelDiv.textContent = textContent;
    labelDiv.style.display = "block";
    labelDiv.style.marginTop = "-1em";
    labelDiv.realValue = distance;
    labelDiv.initialType = unitType;

    const label = new THREE.CSS2DObject(labelDiv);
    label.position.set((initialPoints[1].x + initialPoints[0].x) / 2, (initialPoints[1].y + initialPoints[0].y) / 2, (initialPoints[1].z + initialPoints[0].z) / 2);
    label.layers.set(0);

    return label;
};

function LineFactory() {
    console.log("FACTORY")
    return new Line();
};

export default {
    LineFactory
};
